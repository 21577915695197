"use client";

import { Fragment, useState } from "react";
import styles from "./faq.module.scss";
import cn from "clsx";

export default function Faq({
  data,
}: {
  data: { enabled: boolean; Question: string; Answer: string }[];
}) {
  // Initialize state to keep track of which question is open
  const [openQuestion, setOpenQuestion] = useState<number | null>(null);

  // Function to toggle the open question
  const toggleQuestion = (index: number | null) => {
    if (openQuestion === index) {
      // If the clicked question is already open, close it
      setOpenQuestion(null);
    } else {
      // Otherwise, open the clicked question
      setOpenQuestion(index);
    }
  };

  return (
    <Fragment>
      {data.map((item, index) => (
        <Fragment key={index}>
          <div key={index} className={styles.root}>
            <div
              className="cursor-pointer flex justify-between items-center text-primary-blue"
              onClick={() => toggleQuestion(index)}
            >
              <h2
                className={cn(styles.question, {
                  [styles.activeQuestion]: openQuestion === index,
                })}
              >
                {item.Question}
              </h2>
              <span className="text-primary-blue text-2xl">
                {openQuestion === index ? "-" : "+"}
              </span>
            </div>
            <div
              className={cn(styles.answer, {
                [styles.openAnswer]: openQuestion === index,
              })}
            >
              <pre>{item.Answer}</pre>
            </div>
          </div>
          <hr className="text-[#D4D4D4] stroke-[1.5px]" />
        </Fragment>
      ))}
    </Fragment>
  );
}
